import { Icon } from "@uxf/ui/icon";

export function WhyFlexSection() {
    return (
        <div className="sm:py-14 container px-4 xs:px-0 grid sm:grid-cols-2 sm:gap-8 pt-[660px] sm:pb-12 pb-8 max-xs:mt-36 max-sm:mt-12">
            <div>
                <p className="uxf-typo-body pb-10">
                    Kompatibilní značky střídačů:<span className="font-bold"> Solax</span>,
                    <span className="font-bold">Victron</span>, <span className="font-bold">GoodWe</span> a{" "}
                    <span className="font-bold">Growatt</span>.
                </p>
                <div className="flex space-x-6">
                    <div className="bg-bg-house text-house rounded-full p-2 self-start">
                        <Icon name="house" size={44} />
                    </div>
                    <div>
                        <h2 className="pt-4 uxf-typo-h5 mb-4">Jak funguje technologie dálkového řízení?</h2>
                        <p className="uxf-typo-body text-lightLow mb-10">
                            Naše technologie sleduje vytížení elektrizační soustavy a&nbsp;v&nbsp;mezních situacích
                            redukuje přetoky z&nbsp;FVE do&nbsp;distribuční sítě. Regulace přetoků je omezena
                            na&nbsp;maximálně 5% hodin z&nbsp;kalendářního roku. Efektivní řízení přetoků zásadně
                            snižuje provozní náklady spojené s&nbsp;výkupem solární elektřiny a&nbsp;umožňuje nám
                            nabídnout vyšší výkupní cenu.
                        </p>
                    </div>
                </div>
                <div className="flex space-x-6">
                    <div className="bg-bg-leaf text-leaf rounded-full p-2 self-start">
                        <Icon name="money" size={44} />
                    </div>
                    <div>
                        <h2 className="pt-4 uxf-typo-h5 mb-4">Co spoluprací v&nbsp;rámci programu získáte?</h2>
                        <ul className="uxf-typo-body text-lightLow  mb-10 list-disc">
                            <li>
                                <span className="font-bold">Vyšší cena vykupu:</span> Díky řízení přetoků získáte
                                za&nbsp;vyrobenou elektřinu vyšší výkupní cenu.
                            </li>
                            <li>
                                <span className="font-bold">Řízení přetoků zdarma:</span> Zařízení s&nbsp;naší
                                technologií vám poskytneme zcela zdarma a&nbsp;bezplatně vám bude sloužit po celou dobu
                                naší spolupráce.
                            </li>
                            <li>
                                <span className="font-bold">Individuální nabídka:</span> Připravíme pro vás optimální
                                řešení podle specifikací vaší FVE.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="flex space-x-6">
                    <div className="bg-bg-banknote text-banknote rounded-full p-2 self-start">
                        <Icon name="bulb" size={44} />
                    </div>
                    <div>
                        <h2 className="pt-4 uxf-typo-h5 mb-4">Jak vstoupit do programu?</h2>
                        <p className="uxf-typo-body text-lightLow  mb-10">
                            Vyplňte registrační formulář a my vás budeme postupně kontaktovat s&nbsp;podrobnějšími
                            informacemi. Omlouváme se všem, které z&nbsp;kapacitních důvodů nebudeme moci zařadit do
                            prvotní fáze programu, a děkujeme za trpělivost.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
