import { HomepageForm } from "@main-zone/forms/homepage-form/homepage-form";
import { Widget } from "@ui/components/widget/widget";

export function HomepageJumbotron() {
    return (
        <div className="pt-12 relative sm:pt-28 z-1 bg-primary-jumbotron min-h-96 px-4 pb-8">
            <div className="text-invertedHigh container xs:px-0 sm:grid grid-cols-2 gap-8">
                <div>
                    <h1 className="sm:pb-0 pb-8 sm:text-desktopH3 font-medium text-mobileH4 justify-items-center">
                        Program výkupu elektřiny z&nbsp;FVE s&nbsp;bezplatným dálkovým řízením přetoků
                    </h1>
                </div>
                <div className="flex relative sm:justify-end justify-center">
                    <Widget className="max-w-[450px] absolute w-full sm:-top-4">
                        <HomepageForm />
                    </Widget>
                </div>
            </div>
        </div>
    );
}
