import { FaqItem } from "@ui/components/faq-item/faq-item";

const FAQ_ITEMS: { question: string; answer: string }[] = [
    {
        question: "Jak zjistím značku a\xa0typ střídače/kapacitu baterie?",
        answer: "Obvykle se\xa0uvádí v\xa0dokumentaci, kterou jste k\xa0vaší FVE dostali od\xa0instalační firmy. Tyto informace najdete i\xa0na\xa0výrobním štítku střídače nebo baterie. Pomůže také FVE aplikace nebo webové prostředí FVE, které by vám měla zpřístupnit vaše instalační firma. Můžete ji také přímo oslovit a\xa0konkrétní údaje si vyžádat. Případně zařízení prostě vyfoťte (pokud možno z\xa0více úhlů) a\xa0snímky nám pošlete e-mailem, identifikujeme je my.",
    },
    {
        question: "Jaké typy zařízení jsou podporované pro účast v\xa0programu?",
        answer: "Spolupráci můžeme nabídnout pro značky Solax, GoodWe a\xa0Victron, aktuálně pracujeme na\xa0rozšíření podpory pro střídače značky Growatt.",
    },
    {
        question: "Kdy získám vyšší výkupní cenu?",
        answer: "Zahájení výkupu elektřiny v\xa0rámci programu řízení přetoků závisí na\xa0úspěšné instalaci naší technologie v\xa0podmínkách vaší FVE.",
    },
];

export function FaqSection() {
    return (
        <div className="pb-14 container px-4 xs:px-0 ">
            <div>
                <h3 className="pb-12 uxf-typo-h4">Často kladené otázky</h3>
                <div>
                    {FAQ_ITEMS.map((item, index) => (
                        <FaqItem key={index} answer={item.answer} question={item.question} />
                    ))}
                </div>
            </div>
        </div>
    );
}
