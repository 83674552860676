import { Disclosure, Transition } from "@headlessui/react";
import { Icon } from "@uxf/ui/icon";

interface Props {
    answer: string;
    question: string;
}

export function FaqItem(props: Props) {
    return (
        <Disclosure as="div" className="w-full py-6 border-b-2">
            {({ open }) => (
                <>
                    <Disclosure.Button className="text-left w-full flex justify-between items-center gap-2">
                        <div className="uxf-typo-medium">{props.question}</div>
                        <Icon className="text-primary-jumbotron" size={16} name={open ? "minus" : "plus"} />
                    </Disclosure.Button>
                    <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-90 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-100 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-90 opacity-0"
                    >
                        <Disclosure.Panel className="uxf-typo-body text-lightLow pt-5 transition">
                            {props.answer}
                        </Disclosure.Panel>
                    </Transition>
                </>
            )}
        </Disclosure>
    );
}
