import { FaqSection } from "@main-zone/components/faq-section/faq-section";
import { HomepageJumbotron } from "@main-zone/components/homepage-jumbotron/homepage-jumbotron";
import { WhyFlexSection } from "@main-zone/components/why-flex-section/why-flex-section";
import { HomepageHeader } from "@shared/components/header/homepage-header";
import { Helmet } from "@shared/components/helmet/helmet";
import { Layout } from "@shared/components/layout/layout";

export function Index() {
    return (
        <>
            <Helmet title="Vyberte si svůj tarif" />
            <HomepageHeader className="bg-white" logo="logo-inverted.svg" />
            <HomepageJumbotron />
            <Layout title="Program výkupu elektřiny">
                <WhyFlexSection />
                <FaqSection />
            </Layout>
        </>
    );
}

export async function getServerSideProps() {
    return {
        props: {},
    };
}
